import React, { forwardRef, useImperativeHandle, useRef, useEffect } from "react";

import {
    Alert,
    Checkbox,
    Radio,
    Textarea,
    Switch,
    Input,
    Drawer,
    Button,
    Typography,
    IconButton,
} from "@material-tailwind/react";
import { AlertIcon } from "./AlertWithIcon";
import { handleBlurEmail } from './EmailUtil'
import { dateTime } from './DateUtil';
import flower from '../images/flowers.webp';
import flower2 from '../images/pilot-flower.jpg'


export function DrawerDefault(props) {


    const openDrawerRight = () => setOpenRight(true);
    const closeDrawerRight = () => setOpenRight(false);
    const [isMsgDelivered, setIsMsgDelivered] = React.useState(false);
    const [email, setEmail] = React.useState({ email: '', error: '' });
    const [checkedDate, setCheckedDate] = React.useState(true);
    const [openRight, setOpenRight] = React.useState(false);



    const [selectedType, setSelectedType] = React.useState('None');

    const handleRadioChange = (name) => {
        setSelectedType(name);
        //setError(false)
    }

    const [isChecked, setIsChecked] = React.useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        //setError(false)
    };

    function updateMsgModel(value) {
        props.func({
            ...props.param,
            deliverDay: value,
        });
    }

    const handleDateChange = (event) => {
        updateMsgModel(event.target.value)
    };

    const handleChangeDate = (e) => {
        setCheckedDate(e.target.checked);
        if (e.target.checked === false) {
            props.func({
                ...props.param,
                deliverEvent: '',
                deliverDay: '',
            });

            sessionStorage.removeItem('deliverDay');

        } else {
            sessionStorage.removeItem('deliverEvent');

        }



    };

    const handleDeliveryEventChange = (event) => {
        props.func({
            ...props.param,
            deliverEvent: event.target.value,
        });
    };


    const handleChangeEmail = (e) => {
        console.log("handleChangeEmail" + e.target.value)
        const { value } = e.target;
        if (value === '' || value === undefined || value === null) {
            setEmail((prevState) => ({
                ...prevState,
                email: value,
                error: null
            }));
        } else {
            setEmail((prevState) => ({
                ...prevState,
                email: value,
            }));
        }
    };

    const internalHandleBlurEmail = (evt) => {
        handleBlurEmail(evt, email, setEmail)
    }


    function handleSaveContent() {
        sessionStorage.setItem('approved', isChecked);
        sessionStorage.setItem('recepient', email.email);
        if (props.param.deliverEvent) {
            sessionStorage.setItem('deliverEvent', props.param.deliverEvent);
        } else {
            sessionStorage.removeItem('deliverEvent');
        }
        if (props.param.deliverDay !== '') {
            sessionStorage.setItem('deliverDay', props.param.deliverDay);
        } else {
            sessionStorage.removeItem('deliverDay');

        }
        setOpenRight(false)
    }


    useEffect(() => {

        if (sessionStorage.getItem('recepient') != null) {
            setEmail((prevState) => ({
                ...prevState,
                email: sessionStorage.getItem('recepient'),
                error: null
            }));
        }

        if (sessionStorage.getItem('deliverEvent') != null && sessionStorage.getItem('deliverEvent') !== "undefined") {
            props.param.deliverEvent = sessionStorage.getItem('deliverEvent');
            setCheckedDate(false);
        }
        if (sessionStorage.getItem('deliverDay') != null && sessionStorage.getItem('deliverDay') !== "undefined") {
            props.param.deliverDay = sessionStorage.getItem('deliverDay');
            setCheckedDate(true);
        }
    }, [props.param]);


    const today = new Date().toISOString().split('T')[0];

    return (
        <React.Fragment>
            <div className="flex flex-wrap gap-4">
                <Button variant='outlined' color="orange" onClick={openDrawerRight}>
                    <Typography className=" text-sm sm:text-normal 	 normal-case	">Delivery settings</Typography></Button>
            </div>
            <Drawer
                placement="right"
                open={openRight}
                onClose={closeDrawerRight}
                className="p-4"
            >
                <div className="mb-2 flex items-center justify-between">
                    <Typography variant="h5" color="orange">
                        Delivery Details
                    </Typography>
                    <IconButton
                        variant="text"
                        color="blue-gray"
                        onClick={closeDrawerRight}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="h-5 w-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </IconButton>
                </div>



                {/* <Typography color="gray" className="mb-8 pr-4 font-normal">
                    Material Tailwind features multiple React and HTML components, all
                    written with Tailwind CSS classes and Material Design guidelines.
                </Typography> */}

                <div className="mb-6 w-4/5 m-auto min-w-fit	">
                    <Input disabled={isMsgDelivered}

                        type="email" name="email" placeholder="Recepient Email" variant="standard"
                        value={email.email || ''}
                        onChange={handleChangeEmail}
                        onBlur={internalHandleBlurEmail}
                    />
                </div>

                <div className='mx-auto w-4/5 mt-auto text-sm	sm:text-base pt-3 sm:pt-0'>
                    <Switch disabled={isMsgDelivered} color="orange"

                        className='bg-orange-200 checked:bg-orange-400 	'
                        id="scheduler"
                        label={checkedDate === true ? "Deliver on a specific date" : "Deliver at a future event"}
                        checked={checkedDate}
                        onChange={handleChangeDate}

                        labelProps={{
                            className: "w-max "
                        }}
                    ></Switch>
                </div>
                <div className='mb-6 m-auto w-4/5'>
                    {checkedDate === true &&
                        <Input type="date" id='deliverDate' disabled={props.param.status === "delivered"}
                            value={props.param.deliverDay && props.param.deliverDay !== 'undefined' ? dateTime(props.param.deliverDay) : ''}
                            onChange={handleDateChange}
                            variant="standard"
                            max="3000-12-31"
                            min={today}
                            className="" />
                    }

                    {checkedDate === false &&
                        <Input disabled={isMsgDelivered}
                            size="lg" type="text" placeholder="Wedding / Baby born / School Graduelt " variant="standard" value={props.param.deliverEvent}
                            onChange={handleDeliveryEventChange} />}
                </div>

                <div className='mb-6 m-auto   w-full'>
                    <Alert color="orange" className="m-auto">
                        <Typography variant="h6" className='text-center '>Send gift with your letter</Typography>
                    </Alert>
                </div>
                <div className='mb-6 m-auto w-4/5' >


                    {/* style={{ backgroundImage: `url(${flower})`, opacity: 0.5 }} */}
                    {/* <img
                        src={flower}
                        alt="card"
                    /> */}
                    <figure className="relative  w-full ">
                        <img

                            className=" rounded-full w-1/2 sm:w-full  m-auto"
                            src={flower2}
                            alt="nature image"
                        />
                        {/* <figcaption className="ml-20 absolute bottom-2  flex rounded-xl border border-white bg-white/65 py-1 px-1 shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
                            <div>
                                <Typography variant="h6" color="black" className="text-center">
                                    99$
                                </Typography>
                            </div>
                        </figcaption> */}
                    </figure>
                    {/* <Typography variant="h6" color="blue-gray" className="text-center">
                        Add flowers to your message
                    </Typography> */}

                    <div className="flex flex-col text-sm">

                        {/* <Checkbox color="orange" label="Add large bouquet - 99$"
                            checked={isChecked}
                            onChange={handleCheckboxChange} />
                        <Checkbox color="orange" label="Add small bouquet - 69$"
                            checked={isChecked}
                            onChange={handleCheckboxChange} /> */}

                        <Radio
                            disabled="true"
                            name="type"
                            color="orange"
                            label="Add bouquet - 99$"
                            checked={selectedType === 'Buy'}
                            onChange={() => handleRadioChange("Buy")}

                        />
                        {/* <Radio
                            disabled="true"
                            name="type"
                            color="orange"
                            label="Add medium bouquet - 90$"
                            checked={selectedType === 'Buy'}
                            onChange={() => handleRadioChange("Buy")}

                        /> */}
                        {/* <Radio
                            disabled="true"
                            name="type"
                            color="orange"
                            label="No thanks"
                            checked={selectedType === 'None'}
                            onChange={() => handleRadioChange("None")}
                        /> */}
                    </div>
                    {/* <figure className="relative  w-full">
                        <img
                            className=" rounded-full w-1/2 "
                            src={flower2}
                            alt="nature image"
                        />
                        { <figcaption className="absolute bottom-2  flex rounded-xl border border-white bg-white/75 py-1 px-1 shadow-lg shadow-black/5 saturate-200 backdrop-blur-sm">
                            <div>
                                <Typography variant="h5" color="blue-gray">
                                    99$
                                </Typography>
                            </div>
                        </figcaption> }
                    </figure> */}
                    {/* <Typography
                        className="mb-3 font-normal"
                        variant="paragraph"
                        color="gray"
                    >
                        Enter bouquet preferencaes, we will try to match it when your letter will be delivered
                    </Typography> */}

                </div>
                <div >
                    <AlertIcon />
                </div>
                {/* <Textarea disabled="true" className="text-xs mb-2" placeholder="Adding bouquet is not avilable in your country"> */}

                {/* <Textarea disabled="true" className="text-xs mb-4" placeholder="Enter bouquet preferencaes (flowers colors / types), we will try to match it when your message will be delivered">  </Textarea>*/}





                {/* <div className="text-sm">
                    <Checkbox color="orange" label="I have approved the terms and conditions."
                        checked={isChecked}
                        onChange={handleCheckboxChange} />
                </div> */}


                <div className='m-auto w-4/5 mt-6 w-full'>

                    <Button onClick={handleSaveContent} variant="outlined" color="orange" className="w-full	">Save</Button>
                </div>
            </Drawer>


        </React.Fragment>
    );

};

