import React, { useRef, useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import i18n from '../i18n';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { dateTime } from './DateUtil';

import { Button, Input, Switch, Option, Select } from "@material-tailwind/react";
import { DrawerDefault } from './mydrawer';
import ComplexNavbar from './Header';
import { MyProSideBar } from './ProSideBar';
import MyQuill from './MyQuill';
import { InformationCircleIcon } from "@heroicons/react/24/outline"
import { handlePasteEmail, handleKeyDownEmail, handleBlurEmail, handleDeleteEmail, isValid } from './EmailUtil'
import {
  Spinner,
  Drawer,
  CardHeader,
  CardFooter,
  Radio,
  Collapse,
  Card,
  CardBody,
  Alert,
  Checkbox,
  Textarea,
  Popover,
  PopoverHandler,
  PopoverContent,
  Typography,
  IconButton
} from "@material-tailwind/react";

import { DialogWithForm } from "./FlowerDialog"
import flower from '../images/flowers.webp';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Footer } from '../LandingPage/Footer';

function MyMessage() {
  console.log("RENDER MyMessage");
  const { t } = useTranslation();
  const quillRef = useRef();

  const [load, setLoad] = useState(true);
  const [openPopover, setOpenPopover] = useState(false);
  const [isMsgDelivered, setIsMsgDelivered] = useState(false);
  const [isSharedWithMe, setIsSharedWithMe] = useState(false);
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [checkedRecepient, setCheckedRecepient] = useState(true);
  const [checkedDate, setCheckedDate] = useState(true);
  const [recepientEmails, setRecepientEmails] = useState({ email: '', emails: [], });
  const [email, setEmail] = useState({ email: '', error: '' });

  const [msgModel, setMsgModel] = useState({
    id: '',
    content: '',
    title: '',
    deliverDay: '',
    deliverEvent: '',
    to: '',
    gift: '',
    status: '',
  });

  const [open, setOpen] = React.useState(false);

  const toggleOpen = () => setOpen((cur) => !cur);

  const triggers = {
    onMouseEnter: () => setOpenPopover(true),
    onMouseLeave: () => setOpenPopover(false),
  };



  function updatePanelLoad() {
    setLoad(false)
  }

  function updateMsgModel(value) {
    setMsgModel({
      ...msgModel,
      deliverDay: value,
    });
  }

  const handleDateChange = (event) => {
    updateMsgModel(event.target.value)
  };

  const handleDeliveryEventChange = (event) => {
    setMsgModel({
      ...msgModel,
      deliverEvent: event.target.value,
    });
  };


  const handleChangeDate = (e) => {
    setCheckedDate(e.target.checked);
    if (e.target.checked === false)
      setMsgModel({
        ...msgModel,
        deliverDay: '',
      });
  };

  const handleChangeEmail = (e) => {
    console.log("handleChangeEmail" + e.target.value)
    const { value } = e.target;
    if (value === '' || value === undefined || value === null) {
      setEmail((prevState) => ({
        ...prevState,
        email: value,
        error: null
      }));
    } else {
      setEmail((prevState) => ({
        ...prevState,
        email: value,
      }));
    }
  };


  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  async function handleSaveContent() {

    //let approved = sessionStorage.getItem('approved')
    // if (approved === "false") {
    //   setError("Please approve term and condition")
    //   setAlert(true)
    //   await delay(2000)
    //   setAlert(false)
    //   setError('')
    // } else {

    email.email = sessionStorage.getItem('recepient');
    if (sessionStorage.getItem('deliverEvent') != null)
      msgModel.deliverEvent = sessionStorage.getItem('deliverEvent');
    if (sessionStorage.getItem('deliverDay') != null)
      msgModel.deliverDay = sessionStorage.getItem('deliverDay');


    const content = quillRef.current.getContent();
    setAlert(false)
    if (!email.email) {
      setError("Recepient was not provided")
      setAlert(true)
      await delay(2000)
      setAlert(false)
      setError('')
    }
    else if (!msgModel.deliverDay && !msgModel.deliverEvent) {
      setError("Delivery setting were not provided")
      setAlert(true)
      await delay(2000)
      setAlert(false)
      setError('')
    }
    else if (!content || content.ops[0].insert === '\n') {
      setError("Message was not provided")
      setAlert(true)
      await delay(2000)
      setAlert(false)
      setError('')
    }
    else {
      setLoading(true)

      setError("Saving message ...");
      setAlert(true);
      // TODO add token and real data from model 
      const postBody = {
        //from: sessionStorage.getItem("loggedInUserEmail"),
        token: sessionStorage.getItem("token"),
        to: email.email,
        deliverAt: msgModel.deliverDay,
        deliverEvent: msgModel.deliverEvent,
        msg: content,
        msgId: msgModel._id,
      };




      console.log("send request to uploadMsg" + JSON.stringify(postBody));
      try {


        const response = await fetch('https://mail2future.azurewebsites.net/api/uploadMsg', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postBody),
        });


        //const response = await axios.post('https://mail2future.azurewebsites.net/api/uploadMsg', postBody);

        setMsgModel(prevModel => ({
          ...prevModel,
          id: response.data,
        }));

        setLoad(true)
        setError("Mssage has been saved");
        await delay(1500)
        setAlert(false)
        setLoading(false)

      } catch (e) {
        console.error("Error send request to uploadMsg", e);
      }
    }
    //}

  };




  const internalHandleBlurEmail = (evt) => {
    handleBlurEmail(evt, email, setEmail)
  }



  const updateSelectedMsg = async (newDate) => {
    console.log(newDate)

    if (Object.keys(newDate).length === 0) {
      setEmail({
        email: '', error: '',
      });
      quillRef.current.clear();
      quillRef.current.enable();
      setIsSharedWithMe(false)
      let el = document.getElementsByClassName("ql-toolbar")[0]
      el.style["display"] = "block"
      setMsgModel({});
      sessionStorage.removeItem('recepient');
      sessionStorage.removeItem('deliverDay');
      sessionStorage.removeItem('deliverEvent');


    } else {
      setEmail({
        email: newDate.to,
      });

      setMsgModel({
        ...msgModel,
        deliverDay: newDate.deliverAt,
        deliverEvent: newDate.deliverEvent
      });



      sessionStorage.setItem('recepient', newDate.to);
      sessionStorage.setItem('deliverDay', newDate.deliverAt);
      sessionStorage.setItem('deliverEvent', newDate.deliverEvent);


      if (newDate.deliverEvent) {
        setCheckedDate(false)
      } else {
        setCheckedDate(true)
      }

      if (newDate.to === sessionStorage.getItem("loggedInUserEmail")) {
        setIsSharedWithMe(true)
        quillRef.current.disable()
        let el = document.getElementsByClassName("ql-toolbar")[0]
        el.style["display"] = "none"
      } else {
        setIsSharedWithMe(false)
        if (newDate.status === "delivered") {
          quillRef.current.disable()
          setIsMsgDelivered(true)
          let el = document.getElementsByClassName("ql-toolbar")[0]
          el.style["display"] = "none"
        } else {
          quillRef.current.enable()
          setIsMsgDelivered(false)
          let el = document.getElementsByClassName("ql-toolbar")[0]
          el.style["display"] = "block"
        }
      }




      const postBody = {
        id: newDate._id,
        token: sessionStorage.getItem("token")
        //from: sessionStorage.getItem("loggedInUserEmail")
      };

      // const response = await fetch('https://mail2future.azurewebsites.net/api/getMsg', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify(postBody),
      // });

      const response = await axios.post('https://mail2future.azurewebsites.net/api/getMsg', postBody);
      console.log(JSON.stringify(response))


      setMsgModel(prevModel => ({
        ...prevModel,
        deliverDay: newDate.deliverAt,
        deliverEvent: newDate.deliverEvent,
        status: newDate.status,
        from: newDate.from,
        to: newDate.to,
        // title
        // gift
      }));

      if (newDate.deliverEvent) {
        setCheckedDate(false)
      } else {
        setCheckedDate(true)
      }

      if (response.data.content) {
        quillRef.current.updateContent(response.data.content.msgContent);
      }
    }


  }



  return (
    <I18nextProvider i18n={i18n}>
      <div className='flex flex-col  h-full'>
        <ComplexNavbar lang={t} />

        <div className="flex h-full overflow-auto /*bg-floralwhite*/">
          <div className='flex  w-full  h-full'>
            {useMemo(() => <MyProSideBar onStateChange={updateSelectedMsg} load={load} onLoad={updatePanelLoad} />, [load])}
            <div className="
                              px-1
                              /*bg-wheat*/
                              flex                    
                              h-full
                              w-full
                              col-span-full
                              flex-col
                ">
              {/* <div className={`self-center lg:self-auto border-b border-t	border-mygray pb-3 pt-3  `} style={{ display: isSharedWithMe ? 'block' : 'none' }}>
                <div className='h-full'>
                


                </div>
              </div> */}
              <div className='flex flex-col justify-between flex-1' >
                <div className="max-h-[45vh] sm:max-h-[77vh]" >
                  <MyQuill ref={quillRef} />
                </div>






                <div className={`self-center	lg:self-auto w-full  flex-bottom`} style={{ display: isSharedWithMe ? 'none' : 'block' }}>
                  <div className='flex w-1/2 m-auto '>
                    {alert &&
                      <Alert color={loading ? 'green' : 'red'}
                        // open={alert}
                        // onClose={() => setAlert(false)}
                        icon={!loading &&
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="h-6 w-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                            />
                          </svg>
                        }
                      >
                        <Typography className="font-medium">{error}</Typography>
                      </Alert>
                    }
                  </div>
                  <div className="
                      h-fit	
                      my-3 
                      flex 
                      justify-between	
                      w-full 
                     
                      lg:flex-row
                      flex-col">
                    {/* border	border-mygray */}


                    <div className='ml-auto mr-6 flex'>
                      <DrawerDefault func={setMsgModel} param={msgModel} />

                      <Button onClick={handleSaveContent} variant='outlined' color="orange" disabled={isMsgDelivered} className="ml-6">
                        <Typography className=" text-sm sm:text-normal 	 normal-case	">Save</Typography>
                      </Button>
                      {/* <Button onClick={openDrawer} disabled={isMsgDelivered} color="green" className="flex justify-center gap-3 min-w-[100px]">
                        <Typography className=" text-sm sm:text-normal 	 normal-case	">Save</Typography>
                      </Button> */}
                    </div>

                    {/* <div className="flex w-full flex-col	justify-center	flex-1 lg:px-1 lg:m-2.5 lg:border-r	lg:border-mygray lg:border-dashed	">

                      <div className="w-4/5 m-auto min-w-fit	">
                        <Input disabled={isMsgDelivered}

                          type="email" name="email" placeholder="Recepient Email" variant="standard"
                          value={email.email || ''}
                          onChange={handleChangeEmail}
                          onBlur={internalHandleBlurEmail}
                        />
                      </div>
                      <div className='text-center'>
                        {email.error && <p className="error">{email.error}</p>}
                      </div>
                    </div> */}



                    {/* <div className="flex w-full flex-col lg:flex-row 	justify-between	 mb-3	flex-1 lg:px-1 lg:m-2.5 sm:border-r	lg:border-mygray lg:border-dashed	">
                      <div className='mx-auto w-4/5 mt-auto text-sm	sm:text-base pt-3 sm:pt-0'>
                        <Switch disabled={isMsgDelivered}

                          className='bg-[#2ec946] checked:bg-blue-400 	'
                          id="scheduler"
                          label={checkedDate === true ? "Deliver at specific date" : "Deliver at future event"}
                          checked={checkedDate}
                          onChange={handleChangeDate}

                          labelProps={{
                            className: "w-max "
                          }}
                        ></Switch>
                      </div>
                      <div className='m-auto w-4/5'>
                        {checkedDate === true &&
                          <Input type="date" id='deliverDate' disabled={msgModel.status === "delivered"}
                            value={msgModel.deliverDay ? dateTime(msgModel.deliverDay) : ''}
                            onChange={handleDateChange}
                            variant="standard"
                            max="9999-12-31"
                            min="2030-01-02"
                            className="" />
                        }

                        {checkedDate === false &&
                          <Input disabled={isMsgDelivered}
                            size="lg" type="text" placeholder="Wedding / Baby born / School Graduelt " variant="standard" value={msgModel.deliverEvent}
                            onChange={handleDeliveryEventChange} />}
                      </div>
                    </div> */}





                    {/* <div className="flex w-full flex-row	justify-around		w-full self-center	flex-1 lg:px-1 lg:m-2.5	items-center	">
                      <div>
                        <DialogWithForm disabled={isMsgDelivered} />
                      </div>
                      <div className='flex  flex-row'>
                        <Button onClick={handleSaveContent} disabled={isMsgDelivered} color="green" className="flex justify-center gap-3 min-w-[100px]">
                          <Typography className=" text-sm sm:text-normal 	 normal-case	">Save</Typography></Button>
                        <Popover open={openPopover} handler={setOpenPopover} >
                          <PopoverHandler  {...triggers} >
                            <InformationCircleIcon className="w-5 h-5 my-auto ml-2 " />
                          </PopoverHandler>
                          <PopoverContent  {...triggers} className="z-10 bg-gray-300		">
                            <Typography variant="small" color="black" className="font-normal">
                              Edit is allowed as long as message wasnt delivered
                            </Typography>
                          </PopoverContent>
                        </Popover>
                      </div>
                    </div> */}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <Footer tt={t} />
      </div>
    </I18nextProvider >
  );
}









export default MyMessage;
