const validator = require('validator');



export const handlePasteEmail = (evt, setEmail) => {
    console.log("handlePasteEmail")
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = isValid(paste)

    if (emails) {

        setEmail((prevState) => ({
            ...prevState,
            email: paste,
            error: null
        }));
    }
};

export const handleKeyDownEmail = (evt, email, setEmail) => {
    console.log("handleKeyDownEmail" + JSON.stringify(email))

    if (["Enter", "Tab", ","].includes(evt.key)) {
        evt.preventDefault();

        var emailTrim = email.email.trim();

        if (emailTrim && isValid(emailTrim, setEmail)) {
            setEmail((prevState) => ({
                ...prevState,
                email: emailTrim,
                error: null
            }));
        }
    }
};



export const handleBlurEmail = (evt, email, setEmail) => {
    console.log("handleBlurEmail" + JSON.stringify(email))

    evt.preventDefault();

    var emailTrim = email.email.trim();
    console.log("handleBlurEmail" + emailTrim)

    if (emailTrim && isValid(emailTrim, setEmail)) {
        setEmail((prevState) => ({
            ...prevState,
            email: emailTrim,
            error: null
        }));
    }

};



export function isValid(email, setEmail) {
    let error = null;


    if (!validator.isEmail(email)) {
        error = `${email} is not a valid email address.`;
    }
    console.log("error" + error)
    if (error) {
        setEmail((prevState) => ({
            ...prevState,
            error: error
        }));

        return false;
    }

    return true;
}




