import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses, MenuItemStyles } from 'react-pro-sidebar';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import { dateTime } from './DateUtil';
import {
  MenuList,
  MenuHandler,
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
} from "@material-tailwind/react";
import {

  MarkAsUnreadOutlined,
  EmailOutlined,
  MenuRounded,
  GroupOutlined,
  MailLockOutlined,
  LogoutOutlined,
  ManageAccountsOutlined,
  GradingOutlined,
  HomeOutlined
} from '@mui/icons-material'

import { useNavigate } from 'react-router-dom';
import { is } from 'date-fns/locale';
import PopoverWithImage from "./CantDeliver";
import { ProfileForm } from './Profile';
import { LongDialog } from "./Terms";

export function MyProSideBar({ onStateChange, onLoad }) {
  console.log("RENDER MyProSideBar ***********************************");

  const [collapsed, setCollapsed] = useState(window.innerWidth <= 768 ? true : false);
  const [menuItems, setMenuItems] = useState([]);
  const [sharedWithMeItems, setSharedWithMeItems] = useState([]);

  const [openProfile, setOpenProfile] = React.useState(false);
  const handleOpenProfile = () => setOpenProfile((cur) => !cur);



  const [openTerm, setOpenTerm] = React.useState(false);
  const handleOpenTerm = () => setOpenTerm((cur) => !cur);

  const navigate = useNavigate();




  const logOut = () => {
    sessionStorage.clear();
    window.dispatchEvent(new StorageEvent("storage"));
    navigate('/');
  };

  const handleToggleCollapse = () => {
    setCollapsed(!collapsed);
  };


  const loadMsg = (selectedMsg) => {
    onStateChange(selectedMsg)
  }

  const newMessage = () => {
    onStateChange({})
  }



  useEffect(() => {
    const fetchData = async () => {

      const data = {
        token: sessionStorage.getItem("token")
        //from: sessionStorage.getItem("loggedInUserEmail")
      };




      try {
        console.log("send request to getallmessages");
        const response = await fetch('https://mail2future.azurewebsites.net/api/getallmessages', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        onLoad()
        const responseData = await response.json();


        if (responseData.content) {

          const { arrayA, arrayB } = responseData.content.reduce((result, obj) => {
            if (obj.to !== sessionStorage.getItem("loggedInUserEmail")) {
              result.arrayA.push(obj);
            }
            if (obj.to === sessionStorage.getItem("loggedInUserEmail")) {
              result.arrayB.push(obj);
            }
            return result;
          }, { arrayA: [], arrayB: [] });

          // TODO order by date first 
          // and events below 
          setMenuItems(arrayA);

          const today = new Date();


          arrayB.forEach(item => {
            if (item.deliverAt != null && new Date(item.deliverAt) <= today) {
              item.status = "delivered"
            }
          });

          setSharedWithMeItems(arrayB)


        } else {
          console.error('Error Messages not found in response:', response.content);
        }
      } catch (error) {
        console.error('Error sending request to getallmessages:', error);
      }
    };

    fetchData(); // Call the fetchData function on component init

  }, [onLoad]); // Empty dependency array triggers the effect once on mount


  const themes = {
    light: {
      sidebar: {
        backgroundColor: '#ffffff',
        color: '#607489',
      },
      menu: {
        menuContent: '#fbfcfd',
        icon: '#0098e5',
        hover: {
          backgroundColor: '#c5e4ff',
          color: '#44596e',
        },
        disabled: {
          color: '#9fb6cf',
        },
      },
    },
    dark: {
      sidebar: {
        backgroundColor: '#0b2948',
        color: '#8ba1b7',
      },
      menu: {
        menuContent: '#082440',
        // icon: '#f0f8ff',
        hover: {
          backgroundColor: '#00458b',
          color: '#b6c8d9',
        },
        disabled: {
          color: '#3e5e7e',
        },
      },
    },
  };

  const theme = "dark";
  const hasImage = true;

  const hexToRgba = (hex: string, alpha: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: '13px',
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: '#b6b7b9',
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(themes[theme].menu.menuContent, hasImage && !collapsed ? 0.4 : 1)
          : 'transparent',
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      '&:hover': {
        backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, hasImage ? 0.8 : 1),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  function breakEmailAddress(email) {
    if (email) {
      var atIndex = email.indexOf('@');

      if (atIndex !== -1) {
        return email.slice(0, atIndex);
      } else {
        return null; // or you can throw an error or handle it in some way
      }
    } else {
      return null;
    }
  }


  return (
    <div style={{ display: "flex", fontFamily: "'Poppins', 'Roboto', sans-serif" /*height: "100vh" */ }}>
      <Sidebar backgroundColor='#0b2948' collapsed={collapsed} rootStyles={{
        color: '#f0f8ff',
      }}>
        <Menu closeOnClick="true" menuItemStyles={menuItemStyles}>

          <MenuItem icon={<MenuRounded />}
            style={{
              display: window.innerWidth <= 768 ? 'none' : 'flex'
            }}
            onClick={handleToggleCollapse}>
            Menu
          </MenuItem>

          <MenuItem label="My Message" icon={<AddIcon />} onClick={newMessage}>
            New Message
          </MenuItem>

          <SubMenu label="My Messages" icon={<MarkAsUnreadOutlined />}>
            {menuItems.map((item, index) => (
              <MenuItem key={index} icon={<EmailOutlined />} onClick={() => loadMsg(item)}>
                <div>
                  <div>To: {breakEmailAddress(item.to)}</div>
                  <div>Deliver: {item.deliverAt ? dateTime(item.deliverAt) : item.deliverEvent}</div>
                </div>
              </MenuItem>
            ))}
          </SubMenu>

          <SubMenu label="Shared with me" icon={<GroupOutlined />}>
            {sharedWithMeItems.map((item, index) => (
              <div>
                <MenuItem key={index} icon={item.status === "delivered" ? <MarkAsUnreadOutlined /> : <MailLockOutlined />}
                  onClick={() => item.status === "delivered" ? loadMsg(item) : ""}>
                  {
                    item.status === "delivered" ?
                      item.deliverAt ? dateTime(item.deliverAt) : item.deliverEvent
                      : <PopoverWithImage me={item} />
                  }

                </MenuItem>
              </div>

            ))}
          </SubMenu>


          {/* <SubMenu label="My Profile" icon={<ManageAccountsOutlined />}>
            <MenuItem label="Profile" icon={<HomeOutlined />}>
              <ProfileForm />
            </MenuItem>
          </SubMenu> */}

          <MenuItem label="Terms" icon={<GradingOutlined />} onClick={handleOpenTerm}>
            <span>Terms <LongDialog open={openTerm} /></span>
          </MenuItem>



          <MenuItem label="Sign out" icon={<LogoutOutlined />} onClick={logOut}>
            Logout
          </MenuItem>

        </Menu>
      </Sidebar>


    </div>
  )
}
