import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
} from "@material-tailwind/react";


export function HorizontalCard(props) {
  const { image, content, thanks, more } = props;

  return (
    <Card shadow={false} className="
        w-full 
        m-auto
        flex-col sm:flex-row 
        max-w-6xl
        mt-3 sm:mt-0
        mb-1
        bg-transparent
       
        ">

      <CardBody className="p-5    w-full         text-3xl	">
        {/* <Typography variant="h6" color="blue" className="mb-4 uppercase">
           ppp
          </Typography> */}
        <svg className="w-full"
          viewBox="0 0 16 16"
          fill="currentColor"
          height="0.8em"
          width="0.8em"

        >
          <path
            fill="currentColor"
            d="M12.5 10A3.5 3.5 0 1116 6.5l.016.5a7 7 0 01-7 7v-2a4.97 4.97 0 003.536-1.464 5.01 5.01 0 00.497-.578 3.547 3.547 0 01-.549.043zm-9 0A3.5 3.5 0 117 6.5l.016.5a7 7 0 01-7 7v-2a4.97 4.97 0 003.536-1.464 5.01 5.01 0 00.497-.578 3.547 3.547 0 01-.549.043z"
          />
        </svg>




        <Typography color="black" className="mb-8 font-caveat text-smallgrandpa sm:text-grandpa	font-semibold	">
          {content}
        </Typography>
        <Typography color="blue-gray" className="mb-2 font-caveat text-smallgrandpa sm:text-grandpa font-semibold	">
          {thanks}
        </Typography>
        {/* <Typography color="blue-gray" className="mb-2 font-caveat text-smallgrandpa sm:text-grandpa">
          {more}
        </Typography> */}

      </CardBody>
      <CardHeader
        shadow={false}
        floated={false}
        className="  
       
        self-center
          m-0 
          sm:w-2/5 
          shrink-0 
          bg-transparent
          "
      >
        <img
          src={image}
          alt="card"
          className="h-3/5 w-3/5 	rounded-3xl	 m-auto bg-transparent mb-5"
        />
      </CardHeader>
    </Card>
  );
}