import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from "@material-tailwind/react";
import { useLocation } from 'react-router-dom';
import App from './App';
import MyMessage from './Pages/MyMessage';
import { GoogleOAuthProvider } from "@react-oauth/google";


function PrivateRoute({ element }) {
  console.log("Render PrivateRoute")
  // TODO check what location add and what replace do - need to remove and check navigation manually 
  const location = useLocation();
  return sessionStorage.getItem("loggedInUserName") !== null ? element : <Navigate to="/" replace state={{ from: location }} />;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId='601315205959-nsm14nnk6iam8mila7fnlt9lk27u3861.apps.googleusercontent.com'>
      <ThemeProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/myMessage" element={<PrivateRoute element={<MyMessage />} />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
