import React, { useRef, useEffect, forwardRef, useCallback, useImperativeHandle } from 'react';
import {
    Popover,
    PopoverHandler,
    PopoverContent,
    Button,
    Typography,
} from "@material-tailwind/react";
import { dateTime } from './DateUtil';
import { GiPartyPopper } from "react-icons/gi";

const PopoverWithImage = ({ me }) => {

    return (
        <div >
            <Popover >
                <PopoverHandler>
                    <span> {me.deliverAt ? me.deliverAt : me.deliverEvent}

                    </span>
                </PopoverHandler>
                <PopoverContent className="z-[999] grid  overflow-hidden p-0 bg-gray-300">
                    <div className="p-4">
                        <GiPartyPopper className="h-8 w-8" />
                        <Typography color="blue-gray" className="mb-2 text-lg font-bold">
                            Hey, you have a letter but you are not allowed to view it yet
                        </Typography>
                        <Typography
                            variant="small"
                            color="gray"
                            className="mb-2 font-normal text-blue-gray-500"
                        >
                            Letter will be avilable after {me.deliverAt !== null ? me.deliverAt : "you will provide us required certificate for" + me.deliverEvent}
                        </Typography>

                    </div>

                </PopoverContent>
            </Popover>
        </div>
    );
};

export default PopoverWithImage;
