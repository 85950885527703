import React from "react";
import {
    Navbar,
    Collapse,
    Typography,
    IconButton,
    Button,
} from "@material-tailwind/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import hard from '../images/hard2.png';

import axios from 'axios';

function NavList() {

    const navigate = useNavigate();


    const login = () => {
        if (sessionStorage.getItem("loggedInUserName") !== null) {
            navigate('/myMessage');
        } else {
            loginG()
        }
    }

    const loginG = useGoogleLogin({
        onSuccess: async ({ code }) => {
            const res = await axios.post('https://mail2future.azurewebsites.net/api/login', {
                code,
            });

            console.log("user is authenticated by server")
            sessionStorage.setItem("loggedInUserName", res.data.name);
            sessionStorage.setItem("loggedInUserEmail", res.data.email);
            sessionStorage.setItem("loggedInUserImage", res.data.picture);
            sessionStorage.setItem("token", res.data.mytoken);

            window.dispatchEvent(new StorageEvent("storage"));

            navigate('/myMessage');

        },
        flow: 'auth-code',
    });
    return (
        <div className="my-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">

            <Typography
                as="li"
                variant="small"
                color="blue-gray"
                className="p-1 font-medium"
            >
                <Button variant="outlined" color="orange" onClick={() => login()} href="#"
                    className="flex items-center hover:text-blue-500 transition-colors">
                    {/* background-color: antiquewhite; border: none; */}
                    Login
                </Button>
            </Typography>


        </div>
    );
}

export function NavbarSimple() {
    const [openNav, setOpenNav] = React.useState(false);

    const handleWindowResize = () =>
        window.innerWidth >= 960 && setOpenNav(false);

    React.useEffect(() => {
        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    return (
        <div className="mx-auto w-full px-8 sm:px-24 py-3 bg-transparent 		rounded-none	">
            <div className="flex items-center justify-between text-blue-gray-900">
                <div className="flex items-center">
                    <svg
                        viewBox="0 0 24 24"
                        fill="#FC6A03"
                        height="2em"
                        width="2em"

                    >
                        <path d="M20 4H6c-1.103 0-2 .897-2 2v5h2V8l6.4 4.8a1.001 1.001 0 001.2 0L20 8v9h-8v2h8c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm-7 6.75L6.666 6h12.668L13 10.75z" />
                        <path d="M2 12h7v2H2zm2 3h6v2H4zm3 3h4v2H7z" />
                    </svg>
                    {/* <img src="../hard2.png" alt="" style={{ width: '40px' }} /> */}
                    <Typography
                        as="a"
                        href="#"
                        variant="h6"
                        className="mr-4 cursor-pointer py-1.5 ml-3 text-myorange"
                    >

                        FutureMsgs.com
                    </Typography>

                </div>

                <div >
                    <NavList />
                </div>

            </div>

        </div>
    );
}