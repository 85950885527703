import { Typography } from '@material-tailwind/react';
import React, { Component } from 'react';

export class Footer extends Component {
  render() {
    const { tt } = this.props;
    return (
      <div className="mx-auto w-full   bg-maybe 	px-8 sm:px-24	 font-roboto 	text-sideBlue text-xs		">
        <div className="flex items-center justify-between text-blue-gray-900">


          <div className="tile-marker text-futura text-center p-2 self-center	">© 2023 FutureMsgs.com</div>
          {/* <div className="tile-marker text-futura text-center p-2 mx-auto"></div>
          <div className="tile-marker text-futura text-center p-2 mx-auto"></div> */}
          <div className="tile-marker text-futura text-center p-2 mr-6 flex items-center	">

            <svg
              viewBox="0 0 24 24"
              fill="currentColor"
              height="2em"
              width="2em"

            >
              <path d="M19.07 13.88L13 19.94V22h2.06l6.06-6.07m1.58-2.35l-1.28-1.28a.517.517 0 00-.38-.17c-.15.01-.29.06-.39.17l-1 1 2.05 2 1-1c.19-.2.19-.52 0-.72M11 18H4V8l8 5 8-5v2h2V6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h7v-2m9-12l-8 5-8-5h16z" />
            </svg>
            <Typography className="ml-3 text-xs	"> <a href=" https://mail.google.com/mail/?view=cm&fs=1&to=info.futuremsgs@gmail.com">Contact us</a>


              {/* {tt('landing.greeting')} */}
            </Typography>

          </div>
        </div>
      </div>
    )
  }
}
