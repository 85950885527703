import React, { useRef, forwardRef, useImperativeHandle } from 'react';

// Quill
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ImageResize from 'quill-image-resize-module-react';



const fontSizeArr = ['8px', '9px', '10px', '12px', '14px', '16px', '20px', '24px', '32px', '42px', '54px', '68px', '84px', '98px'];

var Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);


window.Quill = Quill

Quill.register('modules/imageResize', ImageResize);


class CustomImageBlot extends Quill.import('formats/image') {
  static blotName = 'custom-image';

  // Step 2: Register the style attribute with the Parchment registry
  static formats(domNode) {
    return {
      ...super.formats(domNode),
      style: domNode.getAttribute('style') || '',
    };
  }

  format(name, value) {
    // Step 3: Modify the format method to handle the style attribute
    if (name === 'style') {
      this.domNode.setAttribute('style', value);
    } else {
      super.format(name, value);
    }
  }
}

// Step 4: Add the style attribute to the list of allowed formats
//const CustomImageBlot = Quill.import('formats/image');
CustomImageBlot.blotName = 'image';
CustomImageBlot.className = 'ql-image';
CustomImageBlot.tagName = 'img';
Quill.register(CustomImageBlot, true);


const MyQuill = forwardRef((props, ref) => {
  const quillRef = useRef();


  useImperativeHandle(ref, () => ({
    disable() {
      quillRef.current.getEditor().enable(false);
    },
    enable() {
      quillRef.current.getEditor().enable(true);
    },
    updateContent(content) {
      quillRef.current.getEditor().setContents(JSON.parse(content).ops);
    },
    clear() {
      quillRef.current.getEditor().setContents([{ insert: '\n' }]);
    },
    getContent() {
      if (quillRef.current) {
        return quillRef.current.getEditor().getContents();
      }
      return null;
    },
  }));



  const modules = {
    toolbar: [
      [
        { 'header': '1' },
        { 'header': '2' },
        { 'font': [] }
      ],
      [{ 'size': fontSizeArr }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
      [
        { 'list': 'ordered' },
        { 'list': 'bullet' },
        { 'indent': '-1' },
        { 'indent': '+1' }
      ],
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'align': [] }],
      ['link', 'image'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize', 'Toolbar'] /*, 'Toolbar'*/
    }
  };

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block',
    'list', 'bullet', 'indent',
    'color', 'background', 'align',
    'link', 'image', 'width', 'float', 'display', 'margin', 'custom-image', 'style'
  ];

  return (
    <ReactQuill
      className='h-full'
      // readOnly="false"
      ref={quillRef}
      theme="snow"
      //value={data}
      // onChange={setQuillValue}                  
      modules={modules}
      formats={formats}
      placeholder={'Write a message to yourself or your relatives \n Select message arrivel by future date or event.'}
    />
  );
});

export default MyQuill;
