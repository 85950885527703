import React, { useRef, useEffect, useState } from 'react';
import { Carousel } from "@material-tailwind/react";

import axios from 'axios';
// components 
import { Banner } from './LandingPage/Banner';
import { Footer } from './LandingPage/Footer';
import { CardWithLink } from './LandingPage/CardWithLink';
import { HorizontalCard } from './LandingPage/storyCard';
import solider from './images/solider2.jpeg';
import oldWoman from './images/oldwoman.jpg';
import { CarouselWithContent } from "./LandingPage/carusel";
import reading from './images/reading.png';
import bb from './images/bb.jpg';
import cc from './images/cc.jpg';
// import ReactPlayer from 'react-player'


import { AccordionCustomIcon } from './LandingPage/question';
// i18n
import i18n from './i18n';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Button, Typography } from '@material-tailwind/react';
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
// https://icon-sets.iconify.design/gala/settings/
import { NavbarSimple } from './LandingPage/Header';

import { useMediaQuery } from 'react-responsive';


function App() {
    console.log("Render App")
    const navigate = useNavigate();
    const { t } = useTranslation();

    const login = () => {
        if (sessionStorage.getItem("loggedInUserName") !== null) {
            navigate('/myMessage');
        } else {
            loginG()
        }
    }

    const loginG = useGoogleLogin({
        onSuccess: async ({ code }) => {
            const res = await axios.post('https://mail2future.azurewebsites.net/api/login', {
                code,
            });

            console.log("user is authenticated by server")
            sessionStorage.setItem("loggedInUserName", res.data.name);
            sessionStorage.setItem("loggedInUserEmail", res.data.email);
            sessionStorage.setItem("loggedInUserImage", res.data.picture);
            sessionStorage.setItem("token", res.data.mytoken);

            window.dispatchEvent(new StorageEvent("storage"));

            navigate('/myMessage');

        },
        flow: 'auth-code',
    });


    const videoRef = useRef(null);

    useEffect(() => {
        const myElement = videoRef.current;
        if (myElement) {
            //myElement.removeAttribute('controls');
        }
    }, []);




    const isMobile = useMediaQuery({ maxWidth: 767 });


    return (
        <I18nextProvider i18n={i18n}>
            <div className="m-auto 
            
                        flex
                        flex-col 
                        col-span-full 
                        h-screen 
                        h-full
                        overflow-auto ">

                <Banner tt={t} />

                <div className='bg-amber-50' style={{ backgroundImage: `url(${bb})`, backgroundPosition: 'bottom', opacity: 0.9 }}>
                    {/* <button onClick={playPause}>Play/Pause</button> */}

                    {/* <ReactPlayer className="sm:w-1/2 m-auto " controls="True" url='https://morethanmailsite.blob.core.windows.net/mysite/video2.mp4' /> */}

                    <video ref={videoRef} id="video1" className="sm:w-1/2 m-auto " controls  >
                        <source src="https://morethanmailsite.blob.core.windows.net/mysite/video2.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>


                </div>

                <div className=' bg-maybe' style={{ backgroundImage: `url(${cc})`, backgroundPosition: 'center' }}>
                    <div className='flex my-5'>
                        <Typography
                            as="li"
                            variant="small"
                            color="blue-gray"
                            className="p-1 font-medium m-auto"
                        >
                            <Button variant="outlined" size="lg" color="orange" onClick={() => login()} href="#"
                                className="flex items-center hover:text-blue-500 transition-colors font-caveat tracking-wide font-bold text-xl">
                                Start now
                            </Button>
                        </Typography>


                    </div>
                    <div className='w-full flex'>
                        <Typography className="m-auto font-caveat font-bold text-4xl sm:text-6xl text-center	text-myorange ">
                            Turn ordinary mails to special moments
                        </Typography>
                    </div>
                    <div className="sm:w-1/2 m-auto  p-9">

                        {/* <div className='flex items-center  justify-center mb-3'> */}
                        {/* <Typography className="text-2xl sm:text-3xl  ">Question & Answers </Typography> */}
                        {/* </div> */}
                        <AccordionCustomIcon />
                    </div>
                </div>
                {/* <CarouselWithContent /> */}

                {isMobile ? (
                    <div className="bg-black">
                        <Carousel className="" autoplay="true" loop="true" autoplayDelay="15000">
                            <div className="relative h-full w-full">
                                <img
                                    src={oldWoman}
                                    alt="im1"
                                    className="h-full w-full object-cover opacity-75"
                                />
                                <div className="absolute inset-0 grid h-full w-full place-items-center">
                                    {/* bg-black/25 */}
                                    <div className="w-3/4 text-center md:w-2/4">
                                        <Typography
                                            color="white"
                                            className="mb-12"
                                        >
                                            {t('landing.stories.first.p1')}
                                        </Typography>
                                        <Typography
                                            color="white"
                                            className="mb-12"
                                        >
                                            {t('landing.stories.first.p2')}
                                        </Typography>

                                    </div>
                                </div>
                            </div>
                            <div className="relative h-full w-full">
                                <img
                                    src={solider}
                                    alt="im2"
                                    className="h-full w-full object-cover opacity-75"
                                />
                                <div className="absolute inset-0 grid h-full w-full place-items-center">
                                    {/* bg-black/25 */}
                                    <div className="w-3/4 text-center md:w-2/4">
                                        <Typography

                                            color="white"
                                            className="mb-12"
                                        >
                                            {t('landing.stories.second.p1')}
                                        </Typography>
                                        <Typography

                                            color="white"
                                            className="mb-12"
                                        >
                                            {t('landing.stories.second.p2')}
                                        </Typography>

                                    </div>
                                </div>
                            </div>
                            {/* <div className="bg-transparent bg-cover" style={{ backgroundImage: `url(${oldWoman})`, backgroundPosition: 'center', opacity: '0.7' }}>

                                <div className='m-8'>

                                    <Typography color="black" className="mb-8 font-caveat text-smallgrandpa sm:text-grandpa	font-semibold	">
                                        {t('landing.stories.first.p1')}
                                    </Typography>

                                    <Typography color="black" className="mb-8 font-caveat text-smallgrandpa sm:text-grandpa	font-semibold	">
                                        {t('landing.stories.first.p2')}
                                    </Typography>
                                </div>
                            </div>
                            <div className="bg-transparent bg-cover" style={{ backgroundImage: `url(${solider})`, backgroundPosition: 'center', opacity: '0.7' }}>

                                <div className='m-8'>

                                    <Typography color="black" className="mb-8 font-caveat text-smallgrandpa sm:text-grandpa	font-semibold	">
                                        {t('landing.stories.second.p1')}
                                    </Typography>

                                    <Typography color="black" className="mb-8 font-caveat text-smallgrandpa sm:text-grandpa	font-semibold	">
                                        {t('landing.stories.second.p2')}
                                    </Typography>
                                </div>
                            </div> */}
                        </Carousel>
                        <Footer tt={t} />
                    </div>
                )
                    : (
                        <div className="bg-transparent " style={{ backgroundImage: `url(${bb})`, backgroundPosition: 'center' }}>
                            <div className="bg-transparent m-auto">
                                <div className='flex items-center  justify-center m-3 '>
                                    {/* <Typography className="text-2xl sm:text-3xl  ">User stories</Typography> */}
                                </div>
                                <Carousel className="rounded-xl" autoplay="true" loop="true" autoplayDelay="15000">
                                    <HorizontalCard image={oldWoman}
                                        content={t('landing.stories.first.p1')}
                                        thanks={t('landing.stories.first.p2')}
                                    />
                                    <HorizontalCard image={solider}
                                        content={t('landing.stories.second.p1')}
                                        thanks={t('landing.stories.second.p2')}
                                    />
                                </Carousel>
                            </div>

                            <Footer tt={t} />
                        </div>
                    )}

            </div>
        </I18nextProvider >
    );
}

export default App;
