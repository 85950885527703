import React from "react";

import {
  Navbar,
  Typography,
  Button,
  Menu,
  MenuHandler,
  Avatar,
  Input
} from "@material-tailwind/react";


function ProfileMenu() {
  console.log("RENDER ProfileMenu")

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <Menu open={isMenuOpen} handler={setIsMenuOpen} placement="bottom-end">

      <MenuHandler>
        <Button
          variant="text"
          color="blue-gray"
          className="flex items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5 "
        >
          <Avatar
            referrerPolicy="no-referrer"
            variant="circular"
            size="sm"
            alt="tania andrew"
            className="border border-blue-500 p-0.5"
            src={sessionStorage.getItem("loggedInUserImage")}
          />
        </Button>
      </MenuHandler>
    </Menu>
  );
}

export default function ComplexNavbar({ lang }) {
  return (
    <Navbar className="mx-auto max-w-full	 rounded-none	 p-2 bg-blanchedalmond">

      <div className="mx-auto w-full  flex items-center text-blue-gray-900">
        <div className="flex w-full items-center">
          <svg
            viewBox="0 0 24 24"
            fill="#FC6A03"
            height="2em"
            width="2em"

          >
            <path d="M20 4H6c-1.103 0-2 .897-2 2v5h2V8l6.4 4.8a1.001 1.001 0 001.2 0L20 8v9h-8v2h8c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm-7 6.75L6.666 6h12.668L13 10.75z" />
            <path d="M2 12h7v2H2zm2 3h6v2H4zm3 3h4v2H7z" />
          </svg>
          {/* <img src="../hard2.png" alt="" style={{ width: '40px' }} /> */}
          <Typography
            as="a"
            href="#"
            variant="h6"
            className="mr-4 cursor-pointer py-1.5 ml-3 text-myorange"
          >

            FutureMsgs.com
          </Typography>
          <span className="ml-auto w-fit flex items-center">
            <Typography className="hidden sm:block"> {sessionStorage.getItem("loggedInUserEmail")}</Typography>
            <ProfileMenu />
          </span>
        </div>
      </div>



    </Navbar>
  );
}