import React from "react";
import {
    Accordion,
    AccordionHeader,
    AccordionBody,
    Typography,
    ListItem,
    ListItemPrefix,
    Avatar,
    List
} from "@material-tailwind/react";

import DoneIcon from '@mui/icons-material/Done';

export function AccordionCustomIcon() {
    const [open, setOpen] = React.useState(0);

    const handleOpen = (value) => setOpen(open === value ? 0 : value);


    return (
        <>
            <Accordion open={open === 1} >
                <AccordionHeader
                    className={`sm:text-3xl		`}
                    onClick={() => handleOpen(1)}>What is FutureMsgs ?</AccordionHeader>
                <AccordionBody>
                    <p>
                        <Typography className='sm:text-xl leading-loose'>FutureMsgs is a service which allows you to suprise your lovers by sending them a message to the future.</Typography>
                    </p>
                    <p className="mt-3">
                        <Typography className='sm:text-xl leading-10'>Message delivery can be a future date like 1/1/2030 or future event like your granddaogther wedding even if it's in the next decade.</Typography>
                    </p>

                    {/* <p className="mt-3">
                        <Typography className='sm:text-xl leading-10'>MoreThanMail turns ordinary messages into special moments</Typography>
                    </p>
 */}



                </AccordionBody>
            </Accordion>

            <Accordion open={open === 4} >
                <AccordionHeader className={`sm:text-3xl		`}
                    onClick={() => handleOpen(4)}>How does it work ?</AccordionHeader>
                <AccordionBody>
                    <p>
                        <Typography className='sm:text-xl leading-loose'>As simple as sending an email but with more capabilities: </Typography>
                    </p>
                    <List>
                        <ListItem>
                            <ListItemPrefix>
                                <DoneIcon />
                            </ListItemPrefix>
                            <div>
                                <Typography className='sm:text-xl leading-loose'> Set message arrival date to a future date or future event.</Typography>
                            </div>
                        </ListItem>
                        <ListItem>
                            <ListItemPrefix>
                                <DoneIcon />
                            </ListItemPrefix>
                            <div>
                                <Typography className='sm:text-xl leading-loose'> Message remain anonymus as long as it was not delivered.</Typography>
                            </div>
                        </ListItem>
                        <ListItem>
                            <ListItemPrefix>
                                <DoneIcon />
                            </ListItemPrefix>
                            <div>
                                <Typography className='sm:text-xl leading-loose'> Message can be edit or delete as long as it was not delivered.</Typography>
                            </div>
                        </ListItem>
                        <ListItem>
                            <ListItemPrefix>
                                <DoneIcon />
                            </ListItemPrefix>
                            <div>
                                <Typography className='sm:text-xl leading-loose'> Gift (flowers with hard copy of your letter) can be delivered with your message.</Typography>
                            </div>
                        </ListItem>
                    </List>
                    {/* <ul className="dori">
                        <li className="ml-8 p-3"> <Typography className='sm:text-xl leading-loose'> Set message arrival date to a future date or future event.</Typography></li>
                        <li className="ml-8 p-3" > <Typography className='sm:text-xl leading-loose'>Message remain anonymus as long as it was not delivered.</Typography></li>
                        <li className="ml-8 p-3" > <Typography className='sm:text-xl leading-loose'>Message can be edited or delete as long as it was not delivered.</Typography></li>
                        <li className="ml-8 p-3" > <Typography className='sm:text-xl leading-loose'>Gift (flowers with hard copy of your letter) can be added to your message.</Typography>
                            <p className='mt-3'>
                                <Typography className='sm:text-xl leading-loose'>Once message will be exposed to the recepient, the recepient will be asked to provide delivery details in order to deliver yours gift.</Typography></p>

                        </li>
                    </ul> */}
                </AccordionBody>
            </Accordion >
            <Accordion open={open === 5} >
                <AccordionHeader className={`sm:text-3xl		`}
                    onClick={() => handleOpen(5)}>How recepient will be notify ?</AccordionHeader>
                <AccordionBody >

                    <p >
                        <Typography className='sm:text-xl leading-loose'>On message submit you can choose between notify recepient now (default) or notify in the future.</Typography>
                    </p>
                    <p className="mt-3">
                        <Typography className='sm:text-xl leading-loose'>With both options, the recipient will receive an email indicating that they have a pending message from an anonymous sender.</Typography>
                    </p>
                    <List>
                        <ListItem>
                            <ListItemPrefix>
                                <DoneIcon />
                            </ListItemPrefix>
                            <div>
                                <Typography className='sm:text-xl leading-loose'> If message is delivered by date -  recepient will be notify again when the message can be exposed.</Typography>
                            </div>
                        </ListItem>
                        <ListItem>
                            <ListItemPrefix>
                                <DoneIcon />
                            </ListItemPrefix>
                            <div>
                                <Typography className='sm:text-xl leading-loose'> If message is delivered by event - recepient will receive an annual notification requesting a certificate to confirm that the event has occurred.</Typography>
                            </div>
                        </ListItem>
                    </List>
                    {/* <ul className="dori mt-3">
                        <li className="ml-8 p-3 "> <Typography className='sm:text-xl leading-loose'>If message is delivered by date -  recepient will be notify again when the message can be exposed</Typography></li>
                        <li className="ml-8 p-3 "> <Typography className='sm:text-xl leading-loose'>If message is delivered by event - recepient will get once a year a notification that he is required to provid a certificae to approve event has been occured.</Typography></li>

                    </ul> */}
                </AccordionBody>
            </Accordion>
            {/* <Accordion open={open === 8}  >
                <AccordionHeader
                    className={`sm:text-3xl		`}
                    onClick={() => handleOpen(8)}>Who use MoreThanMail ? </AccordionHeader>
                <AccordionBody>
                    <p >
                        <Typography className='sm:text-xl leading-loose'>Our users include a diverse range of individuals who wish to send their thought and love to their lovers.</Typography>
                    </p>
                    <p className='mt-3'>
                        <Typography className='sm:text-xl leading-loose'> From seniors to those battling serious illnesses such as cancer and solders which navigate challenging circumstances.</Typography>
                    </p>
                </AccordionBody>
            </Accordion > */}
            <Accordion open={open === 6} >
                <AccordionHeader className={`sm:text-3xl		`}
                    onClick={() => handleOpen(6)}>How much it cost ?</AccordionHeader>
                <AccordionBody>
                    <p>
                        <Typography className='sm:text-xl leading-loose'>Service is totally free.</Typography></p>

                    <p className='mt-3'>
                        <Typography className='sm:text-xl leading-loose'>Our delivery fee is $20 when you opt to include a gift with your message.</Typography></p>
                    {/* Please note that this fee is non-refundable in the event of order cancellation. */}
                </AccordionBody>
            </Accordion>
            {/* <Accordion open={open === 9} >
                <AccordionHeader className={`sm:text-3xl		`}
                    onClick={() => handleOpen(9)}>For how long MoreThanMail can committmed to be in use ?</AccordionHeader>
                <AccordionBody>
                    <p >
                        <Typography className='sm:text-xl leading-loose'>
                            MoreThanMail service will remain avilable till 2050 and hopfully more. </Typography></p>

                    <p className='mt-3'>
                        <Typography className='sm:text-xl leading-loose'>
                            In case our service will go down before, we will notify the sender to decide rather to deliver the message earlier or cancel it, if sender will not response within a month, the message will be delivered to the recepient</Typography></p>
                </AccordionBody>
            </Accordion> */}
            <Accordion open={open === 7} >
                <AccordionHeader className={`sm:text-3xl		`}
                    onClick={() => handleOpen(7)}>What about privecy ?</AccordionHeader>
                <AccordionBody>
                    <p ><Typography className='sm:text-xl leading-loose'>Your data belongs solely to you; we do not engage in any form of data usage. Nevertheless, we recommend refraining from inputting any confidential information.</Typography></p>
                    <p className='mt-3'><Typography className='sm:text-xl leading-loose'>To ensure the security of your data, it is stored on the Microsoft Cloud.</Typography></p>
                </AccordionBody>
            </Accordion >
            {/* <Accordion open={open === 2} >
                <AccordionHeader className={`sm:text-3xl		`}
                    onClick={() => handleOpen(2)}>About us</AccordionHeader>
                <AccordionBody>
                    <p > <Typography className='sm:text-xl leading-loose'>Ex-Microsoft who love their families and wish to stay in touch with them forever.</Typography></p>
                    <p className='mt-3'> <Typography className='sm:text-xl leading-loose'>We are working on AI chat application that will allows you to continue and chat with your lovers. stay tuned work is in progress ...</Typography></p>

                </AccordionBody>
            </Accordion> */}
        </>
    );
}