import React, { useEffect } from "react";
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Typography,
} from "@material-tailwind/react";

export function LongDialog(props) {
    const [open, setOpen] = React.useState(props.open);
    const handleOpen = () => setOpen((cur) => !cur);

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <>
            <Dialog open={open} handler={handleOpen}>
                <DialogHeader>Terms and Conditions</DialogHeader>
                <DialogBody className="h-[42rem] overflow-scroll ">

                    <Typography className="text-lg	font-bold mt-4  text-black	">1. Acceptance of Terms</Typography>
                    <p>By accessing and using this website, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, please refrain from using our website.</p>

                    <Typography className="text-lg	font-bold mt-4	text-black">2. Use of Content</Typography>
                    <p>All content on this website is provided for general information purposes only. We reserve the right to modify or discontinue any part of the content without notice.</p>

                    <Typography className="text-lg	font-bold mt-4	text-black">3. User Account</Typography>
                    <p>To access certain features of this website, including the ability to make purchases, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>

                    <Typography className="text-lg	font-bold mt-4	text-black">4. Privacy Policy</Typography>
                    <p>Your use of this website is also governed by our Privacy Policy, which can be found here.</p>

                    {/* <Typography className="text-lg	font-bold mt-4	text-black">5. Payment and Purchases</Typography>
                    <p>When making purchases on this website, you agree to provide accurate and complete payment information. All transactions are subject to our Payment and Purchase Policy.</p>

                    <Typography className="text-lg	font-bold mt-4	text-black">6. Refund Policy</Typography>
                    <p>Details about our refund policy can be found in our Refund Policy.</p> */}

                    <Typography className="text-lg	font-bold mt-4	text-black">5. Prohibited Activities</Typography>
                    <p>You agree not to engage in any prohibited activities, including but not limited to unauthorized access, data mining, or any action that could disrupt the functionality of the website.</p>

                    <Typography className="text-lg	font-bold mt-4	text-black">6. Intellectual Property</Typography>
                    <p>All content and materials on this website are the property of futuremsgs.com and are protected by intellectual property laws. You may not reproduce, distribute, or display any part of this website without our prior written consent.</p>

                    <Typography className="text-lg	font-bold mt-4	text-black">7. Limitation of Liability</Typography>
                    <p>We are not liable for any direct, indirect, or consequential damages arising out of your use of this website. We do not guarantee the accuracy, completeness, or reliability of any content.</p>

                    {/* <Typography className="text-lg	font-bold mt-4	text-black">10. Governing Law</Typography>
                    <p>These Terms and Conditions are governed by the laws of [Your Jurisdiction]. Any disputes arising out of or related to these terms shall be resolved in the courts of [Your Jurisdiction].</p> */}

                    <Typography className="text-lg	font-bold mt-4	text-black">8. Changes to Terms</Typography>
                    <p>We reserve the right to modify these Terms and Conditions at any time. Your continued use of the website after any changes indicates your acceptance of the modified terms.</p>

                    <Typography className="text-lg	font-bold mt-4	text-black">9. Contact Information</Typography>
                    <p>If you have any questions or concerns regarding these Terms and Conditions, you may contact us at info.futuremsgs@gmail.com.</p>


                    {/* <Typography className="text-2xl	font-bold mt-9	text-black">Payment and Purchase Policy</Typography>

                    <Typography className="text-lg	font-bold mt-4	text-black">1. Payment Methods</Typography>
                    <p>We accept the following payment methods for purchases on our website: credit cards (Visa, MasterCard, American Express), PayPal, and any other methods specified during the checkout process.</p>

                    <Typography className="text-lg	font-bold mt-4	text-black">2. Currency</Typography>
                    <p>All transactions are processed in [Your Currency]. If you are purchasing from a country with a different currency, your bank may charge currency conversion fees.</p>


                    <Typography className="text-lg	font-bold mt-4	text-black">3. Authorization</Typography>
                    <p>Upon completing a purchase, your payment method will be charged for the total amount of the order. By making a purchase, you authorize us to process the payment and complete the transaction.</p>


                    <Typography className="text-lg	font-bold mt-4	text-black">4. Order Confirmation</Typography>
                    <p>You will receive an email confirmation once your order is successfully placed. Please keep this confirmation for your records.</p>


                    <Typography className="text-lg	font-bold mt-4	text-black">5. Pricing</Typography>
                    <p>All prices on our website are listed in [Your Currency] and are inclusive of any applicable taxes. Prices are subject to change without notice.</p>


                    <Typography className="text-lg	font-bold mt-4	text-black">6. Security</Typography>
                    <p>We take the security of your payment information seriously. Our website uses secure encryption to protect your data during the checkout process.</p>


                    <Typography className="text-lg	font-bold mt-4	text-black">7. Cancellations</Typography>
                    <p>If you need to cancel an order, please contact us as soon as possible. Cancellations are subject to our cancellation policy.</p>



                    <Typography className="text-2xl	font-bold mt-9	text-black">Refund Policy</Typography>

                    <Typography className="text-lg	font-bold mt-4	text-black">1. Eligibility</Typography>


                    <p>We want you to be satisfied with your purchase. If you are not completely satisfied, you may be eligible for a refund. Please review the following conditions:</p>

                    <p>You must request a refund within [number of days] days of receiving the product.
                        The product must be unused, in its original packaging, and in the same condition as when you received it.
                        [Additional conditions specific to your business.]</p>


                    <Typography className="text-lg	font-bold mt-4	text-black">2. How to Request a Refund</Typography>
                    <p>To request a refund, please [contact us/email us] with your order number and a detailed explanation of the reason for the refund request. We will review your request and respond within [number of days] days.</p>

                    <Typography className="text-lg	font-bold mt-4	text-black">3. Processing Time</Typography>
                    <p>Refunds will be processed within [number of days] days of receiving your request. The refund will be issued to the original payment method.</p>


                    <Typography className="text-lg	font-bold mt-4	text-black">4. Non-Refundable Items</Typography>
                    <p>Certain items may not be eligible for a refund, including [list of non-refundable items].</p>

                    <Typography className="text-lg	font-bold mt-4	text-black">5. Shipping Costs</Typography>
                    <p>Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.</p>

                    <Typography className="text-lg	font-bold mt-4	text-black">6. Damaged or Defective Items</Typography>
                    <p>If you receive a damaged or defective item, please [contact us/email us] immediately. We will provide instructions on how to return the item and receive a replacement or refund.</p>

                    <Typography className="text-lg	font-bold mt-4	text-black">7. Changes to this Policy</Typography>
                    <p>We reserve the right to update or change our refund policy at any time. Any changes will be effective immediately and will be posted on our website.</p> */}
                </DialogBody>
                <DialogFooter className="space-x-2">
                    <Button variant="gradient" color="gray" onClick={handleOpen}>
                        close
                    </Button>
                </DialogFooter>
            </Dialog>
        </>
    );
}