import React from 'react';

import oldManImage from '../images/oldman.jpg';
import { NavbarSimple } from './Header';

export function Banner(props) {

  const { tt } = props;


  return (
    <div className="
                    grid 
                    grid-rows-oldMan 
                    bg-cover 
                    bg-center 
                    col-span-full
                    min-h-[60%] sm:min-h-[70%]"
      style={{ backgroundImage: `url(${oldManImage})` }}>
      <div className="tile-marker text-center self-center">
        <NavbarSimple />

      </div>
      <div className="tile-marker text-center self-center"></div>
      <div className="tile-marker text-center self-center">
        <div className='pb-2 text-white font-caveat font-bold text-5xl sm:text-6xl tracking-widest'>{tt('landing.banner.p1')} </div>
        <div className='px-10	 pb-4 text-white font-caveat font-bold text-3xl  sm:text-4xl tracking-widest'>{tt('landing.banner.p2')}</div>
      </div>
      {/* <svg className='m-auto'
        fill="white"
        viewBox="0 0 16 16"
        height="3em"
        width="3em"

      >
        <path
          fillRule="evenodd"
          d="M1 8a7 7 0 1014 0A7 7 0 001 8zm15 0A8 8 0 110 8a8 8 0 0116 0zM8.5 4.5a.5.5 0 00-1 0v5.793L5.354 8.146a.5.5 0 10-.708.708l3 3a.5.5 0 00.708 0l3-3a.5.5 0 00-.708-.708L8.5 10.293V4.5z"
        />
      </svg> */}
    </div>
  )
}